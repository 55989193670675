
import { defineComponent, reactive, onMounted } from 'vue'
import Header from './components/header.vue';
import Footer from './components/footer.vue'
import Affix from './components/affix.vue';
export default defineComponent({
    components: {
        Header,
        Footer,
        Affix,
    },
    setup() {
        const features = reactive([
            { name: '无加盟费', value: 0, desc: '不同于传统加盟模式\n爱乐云不收取任何加盟费用' },
            { name: '低分成', value: 1, desc: '费用低，联系紧密\n您的生意就是我们的生意' },
            { name: '低门槛', value: 2, desc: '初始投入少，团队要求低\n小白也能轻松开店' },
            { name: '自主可控', value: 3, desc: '您的门店您做主\n一切细节由您掌控' },
        ])

        onMounted(() => {
            const list = Array.from(document.getElementsByClassName('first-screen')) as Array<HTMLElement>
            list.forEach(el => el.classList.add('fade-in-end'))
        })
        return {
            features
        }
    }
})
