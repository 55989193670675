/**
 * 网站主题色
 * @param color 
 */
export function switchTheme(color: string) {
    document.body.style.setProperty('--theme', color)
}
/**
 * 是否在屏幕可见范围
 * @param el 
 * @param bottomDistance 
 * @returns 
 */
export function isElemVisible(el: Element, bottomDistance: number = 0): boolean {
    const rect = el.getBoundingClientRect()
    let elemTop = rect.top //dom距离屏幕可见顶部的距离
    elemTop += bottomDistance
    return elemTop < window.innerHeight && rect.bottom >= 0
}
/**
 * throttle 节流
 * @param fun 
 * @param delay 
 * @returns Function
 */
export function throttle(fun: Function, delay: number = 300): () => void {
    let timer = 0
    return function (...rest: any) {
        if (!timer) {
            timer = setTimeout(() => {
                fun(...rest)
                timer = 0
            }, delay)
        }
    }
}