import { onActivated } from 'vue'

/**
 * 保证banner加载成功再执行动画
 * @param fun 
 */
export function onBannerLoadAndActivated(fun: () => void) {
    onActivated(() => {
        const homeBanner = document.getElementsByClassName('banner')[0] as HTMLImageElement
        if (homeBanner.complete) {
            fun()
        } else {
            homeBanner.addEventListener('load', fun)
        }
    })
}